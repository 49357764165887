// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cart-checkout-js": () => import("./../src/pages/cart/checkout.js" /* webpackChunkName: "component---src-pages-cart-checkout-js" */),
  "component---src-pages-cart-index-js": () => import("./../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-cart-my-details-js": () => import("./../src/pages/cart/my-details.js" /* webpackChunkName: "component---src-pages-cart-my-details-js" */),
  "component---src-pages-cart-payment-js": () => import("./../src/pages/cart/payment.js" /* webpackChunkName: "component---src-pages-cart-payment-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-getting-around-nz-js": () => import("./../src/pages/getting-around-nz.js" /* webpackChunkName: "component---src-pages-getting-around-nz-js" */),
  "component---src-pages-home-new-zealand-tours-bay-of-islands-tours-awesome-combo-js": () => import("./../src/pages/home/new-zealand-tours/bay-of-islands-tours/awesome-combo.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-bay-of-islands-tours-awesome-combo-js" */),
  "component---src-pages-home-new-zealand-tours-bay-of-islands-tours-cape-reinga-via-90-mile-beach-js": () => import("./../src/pages/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-bay-of-islands-tours-cape-reinga-via-90-mile-beach-js" */),
  "component---src-pages-home-new-zealand-tours-bay-of-islands-tours-index-js": () => import("./../src/pages/home/new-zealand-tours/bay-of-islands-tours/index.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-bay-of-islands-tours-index-js" */),
  "component---src-pages-home-new-zealand-tours-hobbiton-tours-auckland-matamata-return-transfer-js": () => import("./../src/pages/home/new-zealand-tours/hobbiton-tours/auckland-matamata-return-transfer.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-hobbiton-tours-auckland-matamata-return-transfer-js" */),
  "component---src-pages-home-new-zealand-tours-hobbiton-tours-hobbiton-movie-set-tour-from-auckland-js": () => import("./../src/pages/home/new-zealand-tours/hobbiton-tours/hobbiton-movie-set-tour-from-auckland.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-hobbiton-tours-hobbiton-movie-set-tour-from-auckland-js" */),
  "component---src-pages-home-new-zealand-tours-hobbiton-tours-index-js": () => import("./../src/pages/home/new-zealand-tours/hobbiton-tours/index.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-hobbiton-tours-index-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-index-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/index.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-index-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-day-tour-from-queenstown-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-day-tour-from-queenstown-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-tour-christchurch-queenstown-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-tour-christchurch-queenstown-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-tour-from-te-anau-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-tour-from-te-anau-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-tour-mt-cook-tour-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-milford-sound-tour-mt-cook-tour-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-sundowner-tour-from-queenstown-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-queenstown.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-sundowner-tour-from-queenstown-js" */),
  "component---src-pages-home-new-zealand-tours-milford-sound-tours-sundowner-tour-from-te-anau-js": () => import("./../src/pages/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-te-anau.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-milford-sound-tours-sundowner-tour-from-te-anau-js" */),
  "component---src-pages-home-new-zealand-tours-mt-cook-tours-index-js": () => import("./../src/pages/home/new-zealand-tours/mt-cook-tours/index.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-mt-cook-tours-index-js" */),
  "component---src-pages-home-new-zealand-tours-mt-cook-tours-mt-cook-aoraki-queenstown-christchurch-js": () => import("./../src/pages/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-mt-cook-tours-mt-cook-aoraki-queenstown-christchurch-js" */),
  "component---src-pages-home-new-zealand-tours-mt-cook-tours-mt-cook-aoraki-queenstown-return-js": () => import("./../src/pages/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-return.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-mt-cook-tours-mt-cook-aoraki-queenstown-return-js" */),
  "component---src-pages-home-new-zealand-tours-mt-cook-tours-mt-cook-aoraki-js": () => import("./../src/pages/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-mt-cook-tours-mt-cook-aoraki-js" */),
  "component---src-pages-home-new-zealand-tours-test-test-tour-js": () => import("./../src/pages/home/new-zealand-tours/test/test-tour.js" /* webpackChunkName: "component---src-pages-home-new-zealand-tours-test-test-tour-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-travel-advisory-coronavirus-js": () => import("./../src/pages/travel-advisory-coronavirus.js" /* webpackChunkName: "component---src-pages-travel-advisory-coronavirus-js" */)
}

